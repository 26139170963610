import React from "react"
import { Image } from "react-bootstrap"
import { Button} from "react-floating-action-button"
import icon from "../../images/Discord-Logo-Color.svg"

const DISCORD_INVITE_LINK = "https://discord.gg/YdRtKwxJtG"

// Note: this can be easily abstracted to take props but for now just leaving it har
const DiscordButton: React.FC = () => {
    // Hack to disable SSR rendering for this component since it is breaking on production build
    if (typeof window === 'undefined') {
      return null
    }
    return (
        <div className="discord-button-container">
            <Button
                styles={{backgroundColor: "white"}}
                onClick={() => {
                    if (typeof window !== 'undefined') {
                        window.open(DISCORD_INVITE_LINK, "_blank")
                    }
                }}
            >
                    <Image
                        src={icon}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            margin: 0
                        }}
                    />
            </Button>
        </div>
    )
}

export default DiscordButton
