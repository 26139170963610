import { Link } from "gatsby"
import React from "react"
import { Container, Navbar, Nav, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import MintButton from "../buttons/MintButton"
import { isMobile } from "../../utils/device"
import { FaTwitter } from 'react-icons/fa';
import cottonCandy from "../../images/CottonCandy.png"
const styles = require("./navBar.module.scss")

type NavBarProps = {
  pageInfo: {
    curPage: string,
  },
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

const CustomNavbar: React.FC<NavBarProps> = ({ pageInfo = { curPage: "/" }, accountAddress, contract, getAndSetAccount }) => {
  return (
    <Container fluid className={`px-0 main ${styles.navbarContainer}`}>
      <Navbar variant="light" expand="lg" fixed="top" className={styles.navbar}>
        <Link to="/">
          <Navbar.Brand as="span">
            <span className="brand-name">CryptoCandies</span>
            <Image 
            src={cottonCandy} 
            width="30"
            height="30"
            className="nav-image"
            />
          </Navbar.Brand>
        </Link>
        <Nav className="ml-auto">
          <a href="http://twitter.com/CryptoCandies_" style={{ marginTop: "auto", marginBottom: "auto"}} target="_blank">
            <FaTwitter className="nav-image" color={"#c6538c"} />
          </a>
          <Nav.Link href="/donations">Donations</Nav.Link>
          {!isMobile() &&
            <>
              <Nav.Link href="/mycandies">My Candies</Nav.Link>
              <MintButton
                accountAddress={accountAddress}
                contract={contract}
                getAndSetAccount={() => getAndSetAccount()}
              />
            </>
          }
        </Nav>
      </Navbar>
    </Container>
  )
}

export default CustomNavbar
