/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { PageProps } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import Navbar from "../navBar"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"
import icon from "../../images/Twitter-Logo.png"
const styles = require("./layout.module.css")

type LayoutProps = {
  children: React.ReactNode
  pageProps: PageProps,
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}
const Layout: React.FC<LayoutProps> = ({ children, pageProps, accountAddress, contract, getAndSetAccount }) => {
  const { uri = "/" } = pageProps

  const pageInfo = {
    curPage: uri,
  }

  return (
    <>
      <Container fluid className="px-0 main">
        <Navbar pageInfo={pageInfo} accountAddress={accountAddress} contract={contract} getAndSetAccount={() => getAndSetAccount()} />
      </Container>
      <main>{children}</main>
      <Container>
        <Row>
          <Col>
            <footer className={styles.footer}>
              <a href="http://twitter.com/CryptoCandies_" className={styles.twitterLink} target="_blank">
                <Image src={icon} className="twitter-logo" />
              </a>
              <p>© {new Date().getFullYear()}, CryptoCandies.com</p>
            </footer>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Layout
