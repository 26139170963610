import React, { FormEvent, useEffect, useState } from "react"
import { Button, Form, Container, Row, Col, Modal, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Contract } from "web3-eth-contract";
import MetaMaskConnectButton from "./MetaMaskConnectButton";
import { navigate } from 'gatsby';


type MintButtonProps = {
  accountAddress: null | string,
  contract: Contract,
  getAndSetAccount: Function
}

// const SALE_START_TIME = new Date(Date.UTC(2021, 3, 16, 19, 0, 0))

const MintButton: React.FC<MintButtonProps> = ({ accountAddress, contract, getAndSetAccount }) => {
  // General state
  const [isLoading, setLoading] = useState(false);
  const [shouldShowPurhcaseModal, setShouldShowPurchaseModal] = useState<boolean>(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);

  // Contract state
  const [hasSaleStarted, setHasSaleStarted] = useState(false);
  const [currentSupply, setCurrentSupply] = useState<number>(0)
  const [supplyLimit, setSupplyLimit] = useState<number>(0)
  const [priceEstimate, setPriceEstimate] = useState<number>(0)

  // Form state
  const [numCandiesToBuy, setNumCandiesToBuy] = useState<string>("1")

  // Current date
  // const [currentTime, setTime] = useState<Date>(Date())

  // Function to actually do the minting
  const mintCryptoCandy = () => {
    return contract.methods.calculatePrice().call().then((currentPrice: number) => {
      const numCandiesBuying: number = parseInt(numCandiesToBuy)
      return contract.methods.buyMultipleCandies(numCandiesBuying).send({ from: accountAddress, value: currentPrice * numCandiesBuying })
    })
  }

  // Sets initial price
  useEffect(() => {
    if (contract) {
      contract.methods.calculatePrice().call().then((currentPrice: number) => {
        setPriceEstimate(currentPrice / 1000000000000000000)
      })
    }
  }, [contract])

  useEffect(() => {
    // Buy candies
    if (isLoading) {
      mintCryptoCandy().then(() => {
        setLoading(false)
        setShouldShowPurchaseModal(false)
        navigate("/success");
      }).catch(e => {
        setLoading(false)
        setShouldShowError(true);
      });
    }
    if (contract) {
      contract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply)
      })

      contract.methods.CANDY_LIMIT().call().then((candyLimit: number) => {
        setSupplyLimit(candyLimit)
      })

      contract.methods.hasSaleStarted().call().then((hasSaleStarted: boolean) => {
        setHasSaleStarted(hasSaleStarted)
      })
    }
  }, [contract, isLoading, currentSupply]);

  // Constantly update current time - removed because we are post launch
  // useEffect(() => {
  //     const timer = setInterval(() => { // Creates an interval which will update the current data every minute
  //     // This will trigger a rerender every component that uses the useDate hook.
  //     setTime(new Date());
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
  //   }
  // }, []);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  }

  const ethScanAddressLink = `https://etherscan.io/address/${accountAddress}`

  const mintButton = (
    <>
      <Modal
        show={shouldShowPurhcaseModal}
        onHide={() => setShouldShowPurchaseModal(false)}
        backdrop="static"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Container>
            <Row>
              <Col>
                <Modal.Title>Purchase CryptoCandies</Modal.Title>
              </Col>
            </Row>
          </Container>
        </Modal.Header>

        <Form inline noValidate onSubmit={onSubmit}>
          <Modal.Body>
            <Container>
              <Row>
                <Col>
                  <p>Your connected address is <a href={ethScanAddressLink} target="_blank" className="primary-color">{accountAddress}</a>.</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    There are currently {currentSupply}/{supplyLimit} CryptoCandies minted.
                        </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span style={{ paddingRight: "5px" }}>How many CryptoCandies do you want to purchase?</span>
                  <Form.Control
                    as="select"
                    className="my-1 mr-sm-2"
                    onChange={({ target: { value } }) => {
                      contract.methods.calculatePrice().call().then((currentPrice: number) => {
                        setPriceEstimate(value * currentPrice / 1000000000000000000)
                      })
                      setNumCandiesToBuy(value)
                    }}
                  >
                    {[...Array(20).keys()].map((index) => {
                      const number = index + 1
                      return <option key={number} value={number.toString()}>{number}</option>
                    })}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="price-estimate">Price Estimate: {priceEstimate}Ξ</p>
                </Col>
              </Row>
              {shouldShowError &&
                <Row>
                  <Col>
                    <p className="error-text">An error occurred during your purchase. Please try again and post in the Discord if this issue persists.</p>
                  </Col>
                </Row>
              }
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShouldShowPurchaseModal(false)}
              className="candy-button-outline"
            >
              Cancel
                </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={isLoading || !hasSaleStarted}
              className="candy-button-fill"
            >
              {isLoading ? 'Loading…' : 'Purchase'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <OverlayTrigger key="bottom" placement="bottom" overlay={
        !hasSaleStarted ?
        <Tooltip className="mint-tooltip" id="tooltip-disabled">
          CryptoCandies will be available soon!
        </Tooltip> : <div />
      }>
        <div className="d-inline-block">

          <Button
            variant="outline-primary"
            disabled={!hasSaleStarted}
            className="candy-button"
            onClick={() => setShouldShowPurchaseModal(true)}
            style={!hasSaleStarted ? { pointerEvents: 'none', color:"#c6538c" } : undefined}
          >
            Purchase
          </Button>
        </div>
      </OverlayTrigger>
    </>
  )
  return accountAddress ? mintButton : <MetaMaskConnectButton getAndSetAccount={() => getAndSetAccount()} />
}
export default MintButton